<template>
  <AModalContent
    :show-close="true"
    class="lootbox-list-modal"
  >
    <div class="lootbox-list-modal-title">
      {{ typeName }} loot box traits
    </div>
    <div class="lootbox-list-modal-description">
      These are the items that you could get from this box
    </div>
    <div
      v-if="!isLoading"
      class="lootbox-list-modal-items"
    >
      <a
        v-for="(item) in traits"
        :key="item.id"
        href="javascript:void(0)"
        class="lootbox-list-modal-item"
        @click.prevent="magnifyTrait(item)"
      >
        <div class="lootbox-list-modal-item__image">
          <img
            width="80"
            height="80"
            :src="getPreviewImage(item)"
            :alt="item.name"
          >
        </div>
        <div class="lootbox-list-modal-item__content">
          <div class="lootbox-list-modal-item__name">{{ item.name }}</div>
          <div class="lootbox-list-modal-item__minted-count">{{ item.total_minted }} Total Minted</div>
        </div>
      </a>
      <InfiniteLoading @infinite="loadMore" />
    </div>
    <div
      v-if="isLoading"
      class="lootbox-list-modal-loading"
    >
      <img
        src="@/assets/img/spinner-clear.svg"
        class="loader"
        width="50"
      >
    </div>
    <a
      v-if="type !== 'common'"
      href="javascript:void(0)"
      class="lootbox-list-modal-button"
      :class="{ disabled: isOpenning }"
      @click.prevent="openBox"
    >Open {{ typeName }} Box</a>
    <div
      v-if="zoomItem"
      class="lootbox-list-modal-zoom"
      @click.prevent="hideZoom"
    >
      <img
        :src="getPreviewImage(zoomItem)"
        alt="zoom-image"
      >
      <div class="lootbox-list-modal-zoom__name">
        {{ zoomItem.name }}
      </div>
    </div>
  </AModalContent>
</template>
<script>
import { useApesStorageApi } from "@/api/useApesStorageApi"
import { openLootBox } from "@/functions/lootbox/openLootBox"
import { computed, defineComponent, ref } from "vue"
import AModalContent from "../Modal/AModalContent.vue"
import InfiniteLoading from "v3-infinite-loading";
import { captureError } from "@/helpers/captureError";
import { useWeb3Store } from "@/store/web3";
import { connectWallet } from "@/web3";
import { resizeImageCF } from "@/helpers/resize-image-cloudflare";

export default defineComponent({
  components: { AModalContent, InfiniteLoading },
  props: {
    type: String
  },
  setup(props, { emit }) {
    emit('init', { closeOnOverlay: true })
    const allTraits = ref([])
    const traits = ref([])
    const page = ref(1)
    const isLoading = ref(false)
    const fetchItems = async () => {
      isLoading.value = true
      try {
        const data = await useApesStorageApi({ toast: { error: true }, throw: true }).exec({
          url: '/lootBox/traits',
          params: {
            type: props.type
          }
        })
        allTraits.value = data.items.map(item => ({
          ...item
        }))
        loadMore()
      } catch (err) {
        captureError(err)
      }
      isLoading.value = false

    }
    fetchItems()
    const loadMore = () => {
      if (page.value < 0) return
      const perPage = 30
      const currentItems = allTraits.value.slice((page.value - 1) * perPage, page.value * perPage)
      if (allTraits.value.length && !currentItems.length) {
        // no more items
        page.value = -1
        return
      }
      traits.value = [...traits.value, ...currentItems]
      page.value++
    }
    const typeName = computed(() => props.type.charAt(0).toUpperCase() + props.type.slice(1))
    const web3Store = useWeb3Store()
    const isOpenning = ref(false)
    const openBox = async () => {
      isOpenning.value = true
      if (!web3Store.isConnected) {
        const done = await connectWallet()
        if (!done) return
      }
      await openLootBox({ type: props.type, amount: 1 })
      isOpenning.value = false
    }
    const zoomItem = ref()
    const magnifyTrait = (item) => {
      zoomItem.value = item
    }
    const hideZoom = () => {
      zoomItem.value = null
    }
    const getPreviewImage = item => {
      const previewImage = item.rarityUnisexPreviewImageSrc ||
        item.rarityMalePreviewImageSrc ||
        item.rarityFemalePreviewImageSrc;
      return resizeImageCF(previewImage, '512')
    }
    return {
      getPreviewImage,
      hideZoom,
      zoomItem,
      magnifyTrait,
      isLoading,
      loadMore,
      openBox,
      typeName,
      traits, isOpenning

    }
  }
})
</script>
<style lang="scss">
.lootbox-list-modal {
  font-family: var(--font-oxanium);
  background: linear-gradient(180deg, #17111f 0%, #22080a 100%);
  padding: 50px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  max-height: 90%;
  width: 600px;
  &-title {
    font-size: 24px;
    text-align: center;
  }
  &-loading {
    display: flex;
    justify-content: center;
    padding: 50px 30px;
  }
  &-description {
    text-align: center;
    color: #454b52;
  }
  &-items {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    overflow: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  &-item {
    width: 50%;
    display: flex;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    color: #fff;
    text-decoration: none;
    &__image {
      width: 80px;
      min-height: 80px;
      flex-shrink: 0;
      margin-right: 10px;
      img {
        border-radius: 10px;
        max-width: 100%;
      }
    }
    &__name {
      font-weight: normal;
    }
    &__minted-count {
      margin-top: 5px;
      opacity: 0.5;
      font-size: 12px;
    }
  }
  &-button {
    text-transform: uppercase;
    height: 50px;
    background-color: var(--primary);
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    flex-shrink: 0;
    border-radius: 5px;
    font-weight: bold;
    transition: 0.2s;
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
    &:hover {
      filter: brightness(0.8);
    }
  }
  &-zoom {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &__name {
      margin-top: 8px;
      font-size: 18px;
      color: var(--primary);
    }
    &__image {
    }
    img {
      height: auto;
      width: 80%;
      max-width: 400px;
      max-height: 80%;
      object-fit: contain;
    }
  }
  @media screen and (max-width: 576px) {
    padding: 20px;
    padding-top: 40px;
    width: 90%;
    &-item {
      width: 100%;
      padding: 5px 0;
    }
  }
}
</style>